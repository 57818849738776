import { itemIF } from "../../data/types/item";
import { itemTypes } from "../meta/getMetas";
import { getAddAsSiblingOrChild } from "./getAddAsSiblingOrChild";
import { getKidsIds } from "../reducer/items/getDescendantsAndAncestors";

export const getTopItem = (items: itemIF[]): itemIF | undefined => {
  const minLevel = Math.min(...items.map((i) => i.level!));
  const minLevelItems = items.filter((i) => i.level === minLevel);
  if (minLevelItems.length === 1) {
    return minLevelItems[0] as itemIF;
  }
};

export const setLevelAndPosition = ({
  itemType,
  item,
  editItem,
  itemsTo,
}: {
  itemType: itemTypes;
  item: itemIF;
  editItem?: itemIF;
  itemsTo: itemIF[];
}) => {
  if (!editItem) {
    const level1 = itemsTo.filter((i) => i.level === 1);
    const maxPos = Math.max(...level1.map((i) => i.position), 0);
    return {
      ...item,
      parent: 0,
      level: 1,
      position: maxPos + 1,
    };
  }

  const addAsSiblingOrChild = getAddAsSiblingOrChild({
    itemType,
    newItem: item,
    editItem,
  });

  // add as child
  if (addAsSiblingOrChild) {
    const editItemChildren = itemsTo.filter((i) => i.parent === editItem.id);
    const maxPos = Math.max(...editItemChildren.map((i) => i.position), 0);
    return {
      ...item,
      parent: editItem.id,
      level: editItem.level ? editItem.level + 1 : 1,
      position: maxPos + 1,
    };
  }

  // add as sibling
  return {
    ...item,
    parent: editItem.parent,
    level: editItem.level,
    position: editItem.position + 1,
  };
};

export const getNewItem = ({
  itemType,
  itemFrom,
  itemsTo,
  itemToId,
}: {
  itemType: itemTypes;
  itemFrom: itemIF;
  itemsTo: itemIF[];
  itemToId?: number;
}) => {
  let maxIdItemsTo = Math.max(...itemsTo.map((i) => i.id), 0);
  maxIdItemsTo += 1;
  const itemNewId = {
    ...itemFrom,
    id: maxIdItemsTo,
  };

  const itemTo = itemsTo.find((i) => i.id === itemToId);

  return setLevelAndPosition({
    itemType,
    item: itemNewId,
    editItem: itemTo,
    itemsTo,
  });
};

export const addNewItem = ({
  itemType,
  newItem,
  itemsTo,
  itemToId,
}: {
  itemType: itemTypes;
  newItem: itemIF;
  itemsTo: itemIF[];
  itemToId?: number;
}) => {  
  const newItemWithParentLevelPosition = getNewItem({
    itemType,
    itemsTo,
    itemFrom: newItem,
    itemToId,
  });

  const itemsToIncreaseSiblingPosition = itemsTo.map((i) => {
    if (
      i.parent === newItemWithParentLevelPosition.parent &&
      i.position >= newItemWithParentLevelPosition.position
    ) {
      return {
        ...i,
        position: i.position + 1,
      };
    }
    return i;
  });

  const newItemsToAll = [
    ...itemsToIncreaseSiblingPosition,
    newItemWithParentLevelPosition,
  ];

  return {
    items: newItemsToAll,
    editItemId: newItemWithParentLevelPosition.id,
  };
};

/*
  * getChildrenAndResetIdsParentsLevel

  * new item/itemsFrom can be html, svg, or selector
  * if properties and htmlsOSvgs are provided, new item/itemsFrom are selectors 

  * - get descendants of itemOld
  * - on descendants reset id and level and collect new ids as object array with {oldId, newId}
  * - for descendants reset parent id with the help of selectors oldNewIds
  * - if propertiesFrom and newProperties are provided, reset classId of the properties with the help of selectors oldNewIds 
  * - if htmlsOSvgs and newHtmlsOSvgs are provided, reset assignedClassId of the html or svg with the help of selectors oldNewIds
  * - return {newItems, }
  * - reset levels
  * - reset properties and htmlsOSvgs
  * 
  * return new items, and, if provided, new properties and new htmlsOSvgs
*/

type TOldNewIds = { oldId: number; newId: number };

type TResult = {
  items: itemIF[];
  oldNewIds?: TOldNewIds[];
};
export const getChildrenAndResetIdsParentsLevel = ({
  newItem,
  itemIdOld,
  itemsFrom,
}: {
  newItem: itemIF;
  itemIdOld: number;
  itemsFrom: itemIF[];
}): TResult | undefined => {
  const kidsIds = [] as number[];
  const itemOld = itemsFrom.find((i) => i.id === itemIdOld);
  if (!itemOld) {
    console.warn("getChildrenAndResetIdsParentsLevel: itemOld not found");
    return;
  }
  getKidsIds({
    item: itemOld,
    items: itemsFrom,
    kidsIds,
  });
  const kids = itemsFrom.filter((item) => kidsIds.includes(item.id));

  const oldNewIds = [{ oldId: itemIdOld, newId: newItem.id }];
  let newId = newItem.id;
  const levelOldNew = newItem.level! - itemOld.level!;

  const kidsNewId = kids.map((kid) => {
    newId++;
    oldNewIds.push({ oldId: kid.id, newId });
    return { ...kid, id: newId, level: kid.level! + levelOldNew };
  });

  const kidsNewParent = kidsNewId.map((kid) => {
    const parentOldNew = oldNewIds.find((id) => id.oldId === kid.parent);
    return {
      ...kid,
      parent: parentOldNew ? parentOldNew.newId : kid.parent,
    };
  }) as itemIF[];

  let newData = { items: kidsNewParent, oldNewIds } as TResult;
  return newData;
};

export const getSelectorProperties = ({
  propertiesFrom,
  propertiesTo,
  oldNewIds,
}: {
  propertiesFrom: itemIF[];
  propertiesTo?: itemIF[];
  oldNewIds?: TOldNewIds[];
}): itemIF[] => {
  if(!oldNewIds) {
    return propertiesTo || [];
  }
  const addProperties = [] as itemIF[];
  const ptIds = propertiesTo?.map((p) => p.id) || [];
  let ptMaxId = Math.max(...ptIds, 0);

  oldNewIds.forEach((id) => {
    const selectorProperties = propertiesFrom.filter(
      (p) => p.classId === id.oldId
    );
    selectorProperties.forEach((p) => {
      ptMaxId++;
      addProperties.push({
        ...p,
        id: ptMaxId,
        classId: id.newId,
      });
    });
  });
  return [...(propertiesTo || []), ...addProperties];
};
