import {
  metaIF,
  metaFieldIF,
  itemIF,
  TObject,
  groupIF,
  itemValueIF,
} from "../../data/types/item";
import { itemTypes } from "./getMetas";
import { getMetaWithAllFields } from "./getMetaWithAllFields";

const getMetaOptions = (fieldMeta: metaFieldIF) => {
  // the new options are a copy of the options of the main field (if it is a select field) or a one option array with id: "value"
  const selectOptions =
    fieldMeta.type === "select" && fieldMeta.options
      ? [...fieldMeta.options]
      : [
          {
            id: "value",
          },
        ];

  // add auto generated options
  if (fieldMeta.canBeAuto) {
    selectOptions.push({ id: "auto" });
  }
  if (fieldMeta.canBeCalculated) {
    selectOptions.push({ id: "calculate" });
  }
  if (fieldMeta.canBeInherited) {
    selectOptions.push({ id: "inherit" });
  }
  if (fieldMeta.canBeInitial) {
    selectOptions.push({ id: "initial" });
  }
  if (fieldMeta.canBeAddOptions) {
    fieldMeta.canBeAddOptions.forEach((option) => {
      selectOptions.push({ id: option });
    });
  }
  return selectOptions;
};

const getSelectField = (fieldMeta: metaFieldIF) => {
  const selectOptions = getMetaOptions(fieldMeta);

  if (fieldMeta.type === "select" && fieldMeta.options) {
    // add existing options to the auto generated options
    return { ...fieldMeta, options: selectOptions };
  }
  const selectField = {
    name: `${fieldMeta.name}Select`,
    type: "select",
    options: selectOptions,
    onOff: fieldMeta.onOff,
  } as metaFieldIF;

  // is f dependent on other field? set dependentOn on the new select
  if (fieldMeta?.dependentOn) {
    selectField.dependentOn = fieldMeta.dependentOn;
  }

  return selectField;
};

const addInArray = ({
  array,
  str,
  addStr,
  addAfter,
}: {
  array: string[];
  str: string;
  addStr: string;
  addAfter?: boolean;
}) => {
  const index = array.findIndex((f) => f === str);
  if (index === -1) {
    return array;
  }
  if (addAfter) {
    return [...array.slice(0, index + 1), addStr, ...array.slice(index + 1)];
  }
  return [...array.slice(0, index), addStr, ...array.slice(index)];
};

const addField = ({
  fieldMeta,
  newMeta,
}: {
  fieldMeta: metaFieldIF;
  newMeta: metaIF;
}) => {
  const fieldMetaWithCanBeOptions = getSelectField(fieldMeta);

  let newFields = newMeta.fields ? [...newMeta.fields] : ([] as metaFieldIF[]);
  if (fieldMeta.type === "select") {
    newFields = newFields.map((f) => {
      if (f.name === fieldMeta.name) {
        return fieldMetaWithCanBeOptions;
      }
      return f;
    });
  } else {
    newFields = [...newFields, fieldMetaWithCanBeOptions];
    newFields = newFields.map((f) => {
      if (f.name === fieldMeta.name) {
        return {
          ...f,
          dependentOn: {
            field: fieldMetaWithCanBeOptions.name,
            values: ["value"],
          },
        };
      }
      return f;
    });
  }
  let newDefault = newMeta.default
    ? { ...newMeta.default }
    : ({} as itemValueIF);

  newDefault = {
    ...newDefault,
    [fieldMetaWithCanBeOptions.name]: {
      ...newDefault[fieldMetaWithCanBeOptions.name],
      value: "value",
    },
  };

  let newFieldsOrder =
    fieldMeta.type !== "select"
      ? newMeta.fieldsOrder
        ? addInArray({
            array: newMeta.fieldsOrder,
            str: fieldMeta.name,
            addStr: fieldMetaWithCanBeOptions.name,
          })
        : [fieldMetaWithCanBeOptions.name, fieldMeta.name]
      : newMeta.fieldsOrder
        ? newMeta.fieldsOrder
        : [fieldMetaWithCanBeOptions.name];

  let newGroups = newMeta.group ? [...newMeta.group] : ([] as groupIF[]);
  if (fieldMeta.type !== "select") {
    let isInNewMetaGroup = newGroups.find((g) =>
      g.fields.includes(fieldMeta.name)
    );
    if (!isInNewMetaGroup) {
      const newGroup = {
        name: `${fieldMeta.name}G`,
        fields: [fieldMetaWithCanBeOptions.name, fieldMeta.name],
        display: ["select", "value"],
      };
      newGroups = [...newGroups, newGroup];
    } else {
      newGroups = newGroups.map((g) => {
        if (g.fields.includes(fieldMeta.name)) {
          const newGroupFields = g.fields
            ? addInArray({
                array: g.fields,
                str: fieldMeta.name,
                addStr: fieldMetaWithCanBeOptions.name,
              })
            : [fieldMetaWithCanBeOptions.name, fieldMeta.name];

          // TODO, when there are more than 1 canBeFields in the group, the display name is set in the wrong order
          // like select select h/v horizontal vertical instead of h/v select horizontal select vertical

          const gFieldIndex = g.fields?.findIndex((d) => d === fieldMeta.name);
          // if  g.display exists then there should be a value for fieldName at gFieldIndex -> replace with ["select", g.display[gFieldIndex]]
          const fieldDisplayName = g.display?.[gFieldIndex];

          const newGroupDisplay = g.display && fieldDisplayName
            ? [
                ...g.display.slice(0, gFieldIndex),
                "select",
                fieldDisplayName,
                ...g.display.slice(gFieldIndex + 1),
              ]
            
            : ["select", "value"];

          return {
            ...g,
            fields: newGroupFields,
            display: newGroupDisplay,
          };
        }
        return g;
      });
    }
  }

  if (fieldMeta.canBeCalculated) {
    const calcFieldName = `${fieldMeta.name}Calculate`;
    const newField = {
      name: calcFieldName,
      type: "string",
      dependentOn: {
        field:
          fieldMeta.type === "select"
            ? fieldMeta.name
            : `${fieldMeta.name}Select`,
        values: ["calculate"],
      },
      onOff: fieldMeta.onOff,
      wrapperFn: (valueObj) => `calc(${valueObj.value})`,
    } as metaFieldIF;
    newFields = [...newFields, newField];

    newFieldsOrder = addInArray({
      array: newFieldsOrder,
      str: fieldMeta.name,
      addStr: calcFieldName,
      addAfter: true,
    });

    let isInNewMetaGroup = newGroups.find((g) =>
      g.fields.includes(fieldMeta.name)
    );
    if (!isInNewMetaGroup) {
      const newGroup = {
        name: `${fieldMeta.name}G`,
        fields: [fieldMeta.name, calcFieldName],
        display: [fieldMeta.name, "calculate"],
      };
      newGroups = [...newGroups, newGroup];
    } else {
      newGroups = newGroups.map((g) => {
        if (g.fields.includes(fieldMeta.name)) {
          const newGroupFields = g.fields
            ? [...g.fields, calcFieldName]
            : ([] as string[]);

          const newGroupDisplay = g.display
            ? [...g.display, "calculate"]
            : ["select", "calculate"];

          return {
            ...g,
            fields: newGroupFields,
            display: newGroupDisplay,
          };
        }
        return g;
      });
    }

    newDefault = {
      ...newDefault,
      [calcFieldName]: {
        ...newDefault[calcFieldName],
        value: "100% - 10px",
      },
    };
  }

  newMeta.fields = newFields;
  newMeta.fieldsOrder = newFieldsOrder;
  newMeta.group = newGroups;
  newMeta.default = newDefault;
};

// add option to the main field of meta, all fields who will have auto, calculate, inherit, or initial added, must have a main field with type select
//  WARNING: wrapperFn in group and wrapperForGroup must be adapted manually
export const addCanBeFields = (meta?: metaIF): metaIF | undefined => {
  if (!meta || !meta.fields) {
    console.warn("addCanBeFields: no meta or no fields in meta");
    return;
  }
  if (
    !meta.fields.find(
      (f) =>
        f.canBeAuto || f.canBeCalculated || f.canBeInherited || f.canBeInitial
    )
  ) {
    return meta;
  }

  const newMeta = {
    ...meta,
  };

  meta.fields.forEach((f: metaFieldIF) => {
    if (
      f.canBeAuto ||
      f.canBeCalculated ||
      f.canBeInherited ||
      f.canBeInitial
    ) {
      addField({
        fieldMeta: f,
        newMeta,
      });
    }
  });

  return newMeta;
};

const getAddValuesForAdditionalFields = ({
  meta,
  canBeFieldsAddedMeta,
  eiItem,
  addDefaultValues,
}: {
  meta?: metaIF;
  canBeFieldsAddedMeta?: metaIF;
  eiItem?: itemIF;
  addDefaultValues: TObject;
}) => {
  const addFields = canBeFieldsAddedMeta?.fields
    ?.filter((f) => {
      if (meta?.fields && meta.fields.find((fc) => fc.name === f.name)) {
        return false;
      }
      return true;
    })
    .map((f) => f.name);

  if (canBeFieldsAddedMeta && addFields && addFields.length > 0 && eiItem) {
    // do the newly added fields have a value in editItem? no -> add the default value to edit item
    const novalues = addFields.filter((name) => {
      if (!eiItem.value || !eiItem.value[name]) {
        return true;
      }
      return false;
    });
    if (
      novalues?.length &&
      novalues.length > 0 &&
      canBeFieldsAddedMeta.default
    ) {
      novalues.forEach((name) => {
        if (
          canBeFieldsAddedMeta.default &&
          canBeFieldsAddedMeta.default[name]
        ) {
          addDefaultValues[name] = canBeFieldsAddedMeta.default[name];
        }
      });
    }
  }
};

// get static meta plus, for itemIF, fields in meta all, plus canbe fields (auto, calculate, inherit, initial) which are for CSS properties only
export const getMetaWithAllAndCanBeFields = ({
  itemType,
  item,
  addDefaultValues,
}: {
  itemType: itemTypes;
  item: itemIF;
  addDefaultValues?: TObject;
}) => {
  const itemName = item.name;

  const meta = getMetaWithAllFields({ itemType, itemName });
  if (!meta) {
    return;
  }

  const canBeFieldsAddedMeta =
    itemType === itemTypes.CLASSES_CSS ? addCanBeFields(meta) : meta;
  if (!canBeFieldsAddedMeta) {
    return meta;
  }
  if (addDefaultValues) {
    getAddValuesForAdditionalFields({
      meta,
      canBeFieldsAddedMeta,
      eiItem: item,
      addDefaultValues,
    });
  }

  return canBeFieldsAddedMeta;
};
