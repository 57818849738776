import { metaIF, EMetaFieldTypes } from "../../types/item";
import { standardUnits } from "./utils";
import { getValueStr } from "../../../lib/reducer/items/getItemData";

export const CSS_PROPERTIES_TRANSFORM: metaIF[] = [
  {
    name: "backface-visibility",
    category: ["TRANSFORM"],
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        onOff: true,
        options: [
          {
            id: "visible",
          },
          {
            id: "hidden",
          },
        ],
        help:
          "### backface-visibility  \n" +
          "See for example [w3schools - CSS backface-visibility Property](https://www.w3schools.com/cssref/css3_pr_backface-visibility.asp)",
      },
    ],
    group: [
      { name: "mainG", fields: ["main"], display: ["backface-visibility"] },
    ],
    default: { main: { value: "hidden" } },
  },
  {
    name: "matrix",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fieldsOrder: [
      "scaleX",
      "skewY",
      "skewX",
      "scaleY",
      "translateX",
      "translateY",
    ],
    fields: [
      {
        name: "scaleX",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "skewY",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "skewX",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "scaleY",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "translateX",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
      {
        name: "translateY",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "scaleG",
        fields: ["scaleX", "scaleY"],
        display: ["scaleX", "scaleY"],
      },
      {
        name: "skewG",
        fields: ["skewX", "skewY"],
        display: ["skewX", "skewY"],
      },
      {
        name: "translateG",
        fields: ["translateX", "translateY"],
        display: ["translateX", "translateY"],
      },
    ],
    wrapperForGroup: [
      {
        name: "matrixG",
        fields: [
          "scaleX",
          "skewY",
          "skewX",
          "scaleY",
          "translateX",
          "translateY",
        ],
        wrapperFn: (
          { scaleX, skewY, skewX, scaleY, translateX, translateY },
          items
        ) => {
          return `matrix(${getValueStr({
            valObj: scaleX,
          })}, ${getValueStr({
            valObj: skewY,
          })}, ${getValueStr({
            valObj: skewX,
          })}, ${getValueStr({
            valObj: scaleY,
          })}, ${getValueStr({
            valObj: translateX,
          })}, ${getValueStr({
            valObj: translateY,
          })})`;
        },
      },
    ],
    default: {
      scaleX: {
        value: 1,
      },
      skewX: {
        value: 0,
      },
      skewY: {
        value: 0,
      },
      scaleY: {
        value: 1,
      },
      translateX: {
        value: 0,
      },
      translateY: {
        value: 0,
      },
    },
    help:
      "### matrix  \n" +
      "The matrix is build as matrix(scaleX, skewY, skewX, scaleY, translateX, translateY).  \n" +
      "For further information see for example [MDN - Matrix](https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix())",
  },

  {
    name: "perspective",
    category: ["TRANSFORM"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        help:
          "#### perspective  \n" +
          "Defines from how far away the user looks on the screen. `perspective` is set for the parent of the elements to be transformed.  \n" +
          "It activates the 3rd dimension. The smaller its value is, the more dramatic are transforms " +
          "in the 3rd dimension, like `rotateY` or `translateZ`. The higher its value is, the smaller is the visiable effect of 3D-transforms, " +
          "the perspective is from 'far away'. " +
          "With no perspective for the element set or value of 0 for the perspective, there is no movement in the 3rd dimension. " +
          "rotateY, for example, just squeezed the element horizontally, but it doesn't reshape as a real 3D object should.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["perspective"],
      },
    ],
    default: { main: { value: 400, unit: "px" } },
  },
  {
    name: "perspective-origin",
    category: ["TRANSFORM"],
    setOnParent: true,
    fieldsOrder: ["x-position", "y-position"],
    fields: [
      {
        name: "x-position",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "%" },
          { id: "px" },
          { id: "rem" },
          { id: "vw" },
          { id: "vh" },
        ],
        help: "#### perspective-origin  \nSee for example [MDN - perspective-origin](https://developer.mozilla.org/en-US/docs/Web/CSS/perspective-origin)",
      },
      {
        name: "y-position",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: [
          { id: "%" },
          { id: "px" },
          { id: "rem" },
          { id: "vw" },
          { id: "vh" },
        ],
      },
    ],
    group: [
      {
        name: "positionG",
        fields: ["x-position", "y-position"],
        display: ["x-position", "y-position"],
      },
    ],
    default: {
      "x-position": { value: 200, unit: "%" },
      "y-position": { value: 200, unit: "%" },
    },
  },

  {
    name: "rotateX",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        units: [{ id: "deg" }],
        onOff: true,
        wrapperFn: (valueObj, items) =>
          `rotateX(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["rotateX"],
      },
    ],
    default: { main: { value: 45, unit: "deg" } },
  },
  {
    name: "rotateY",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        units: [{ id: "deg" }],
        onOff: true,
        wrapperFn: (valueObj, items) =>
          `rotateY(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["rotateY"],
      },
    ],
    default: { main: { value: 45, unit: "deg" } },
  },
  {
    name: "rotateZ",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        units: [{ id: "deg" }],
        onOff: true,
        wrapperFn: (valueObj, items) =>
          `rotateZ(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["rotateZ"],
      },
    ],
    default: { main: { value: 45, unit: "deg" } },
  },

  {
    name: "scaleX",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        wrapperFn: (valueObj, items) =>
          `scaleX(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["scaleX"],
      },
    ],
    default: { main: { value: 2 } },
  },
  {
    name: "scaleY",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        wrapperFn: (valueObj, items) =>
          `scaleY(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["scaleY"],
      },
    ],
    default: { main: { value: 2 } },
  },
  {
    name: "scaleZ",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        wrapperFn: (valueObj, items) =>
          `scaleZ(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["scaleZ"],
      },
    ],
    default: { main: { value: 2 } },
  },
  {
    name: "skewX",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        units: [{ id: "deg" }],
        onOff: true,
        wrapperFn: (valueObj, items) =>
          `skewX(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["skewX"],
      },
    ],
    default: { main: { value: 30, unit: "deg" } },
  },
  {
    name: "skewY",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        units: [{ id: "deg" }],
        onOff: true,
        wrapperFn: (valueObj, items) =>
          `skewY(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["skewY"],
      },
    ],
    default: { main: { value: 30, unit: "deg" } },
  },
  {
    name: "transform-origin",
    category: ["TRANSFORM"],
    fieldsOrder: ["X", "Y", "Z"],
    fields: [
      {
        name: "X",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        help:
          "#### transform-origin  \n" +
          "Especially relevant for rotations.  \n" +
          "If you rotate around the X axis with `50% 0 0` the element rotates around its upper side, " +
          "with `50% 50% 0` around itself and with `50% 100% 0` around its bottom side.  \n" +
          "If you rotate around the Y axis, the first `transform-origin` value (X) defines the position of the axis: " +
          "`0 50% 0` makes the element rotate around its left side, `50% 50% 0` around itself and `100% 50% 0` around its right side.  \n" +
          "The last value of `transform-origin` moves the element as defined in X and Y, but pushes the x or y axis towards you, " +
          "when Z is posive, or away from you, when Z is negativ. " +
          "`50% 50% 100px` and `rotateX` moves the x axis from the vertical center of the element 100px towards you. " +
          "At 180deg is the element back in its original horizontal and vertical position, but 200px closer to you.",
      },
      {
        name: "Y",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
      },
      {
        name: "Z",
        type: EMetaFieldTypes.number,
        onOff: true,
      },
    ],
    group: [
      {
        name: "XG",
        fields: ["X"],
        display: ["X"],
      },
      {
        name: "YG",
        fields: ["Y"],
        display: ["Y"],
      },
      {
        name: "ZG",
        fields: ["Z"],
        display: ["Z"],
      },
    ],

    default: {
      X: { value: 50, unit: "%" },
      Y: { value: 50, unit: "%" },
      Z: { value: 0 },
    },
  },
  {
    name: "transform-style",
    category: ["TRANSFORM"],
    setOnParent: true,
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.select,
        options: [{ id: "flat" }, { id: "preserve-3d" }],
        onOff: true,
        help:
          "#### transform-style  \n" +
          "`preserve-3d` keeps the 3D effect for the children, when you transform the parent. \n" +
          "For example you have a 3D dice, made from 6 `divs`. " +
          "Those 6 `divs` are within another `div` with `perspective` and `transform-style:preserve-3d` set. " +
          "If you rotate the outer `div`, you'll see all sides of the 3D dice. " +
          "Remove the `transform-style` from the parent or set it to `flat`, and the rotation of the " +
          "outer `div` just shows a flat element rotating instead.",
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["transform-style"],
      },
    ],
    default: { main: { value: "preserve-3d" } },
  },
  {
    name: "translateX",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        wrapperFn: (valueObj, items) =>
          `translateX(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["translateX"],
      },
    ],
    default: { main: { value: 50, unit: "px" } },
    help:
      "Moves the selected element to the right, when the `translateX` value is positive, " +
      "or to the left, when it is negative.",
  },
  {
    name: "translateY",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        wrapperFn: (valueObj, items) =>
          `translateY(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["translateY"],
      },
    ],
    default: { main: { value: 50, unit: "px" } },
    help:
      "Moves the selected element downwards, when the `translateY` value is positive, " +
      "or upwards, when it is negative.",
  },
  {
    name: "translateZ",
    category: ["TRANSFORM"],
    addToShorthand: "transform",
    fields: [
      {
        name: "main",
        type: EMetaFieldTypes.number,
        onOff: true,
        units: standardUnits,
        wrapperFn: (valueObj, items) =>
          `translateZ(${getValueStr({
            valObj: valueObj,
          })})`,
      },
    ],
    group: [
      {
        name: "mainG",
        fields: ["main"],
        display: ["translateZ"],
      },
    ],
    default: { main: { value: 50, unit: "px" } },
    help:
      "Moves the selected element towards you, when the `translateZ` value is positive, " +
      "or away from you, when it is negative.",
  },
];
