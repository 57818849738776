import axios from "axios";
import { snippetIF } from "../../data/types/snippets";
import { QueryGetSnippetsArgs, Snippet } from "cssexy-be/src/generated/graphql";

const GET_SNIPPETS_QUERY = `
  query GetSnippets($filter: SnippetFilterInput, $limit: Int, $sort: SnippetSortInput, $nextToken: String) {
    getSnippets(filter: $filter, limit: $limit, sort: $sort, nextToken: $nextToken) {
      items {
        id
        content
        copyCount
        data
        description
        name
        owner
        public
        updatedAt
      }
      nextToken
    }
  }
`;

const getSnippetsFromServer = async (variables: QueryGetSnippetsArgs) => {
  const graphqlEndpoint = process.env.REACT_APP_SNIPPETS_API_URL || "";
  const apiKey = process.env.REACT_APP_SNIPPETS_API_KEY || "";

  try {
    const requestPayload = {
      query: GET_SNIPPETS_QUERY,
      variables,
    };

    const response = await axios.post(
      graphqlEndpoint,
      JSON.stringify(requestPayload),
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      }
    );
    const fetchedSnippets = response?.data?.data?.getSnippets
      ?.items as Snippet[];

    if (!fetchedSnippets) {
      return [];
    }

    const snippets = fetchedSnippets
      .filter((s) => s)
      .map((snip) => {
        if (!snip.data) {
          return {
            ...snip,
            data: {},
          };
        }

        return {
          ...snip,
          data:
            typeof snip.data === "string" ? JSON.parse(snip.data) : snip.data,
        };
      }) as snippetIF[];

    return snippets;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getFilteredAndSortedSnippets = async ({
  nextToken,
  sortAndFilterParams,
}: {
  nextToken?: string;
  sortAndFilterParams: QueryGetSnippetsArgs;
}) => {
  return getSnippetsFromServer({
    limit: sortAndFilterParams.limit,
    filter: sortAndFilterParams.filter,
    sort: sortAndFilterParams.sort,
    nextToken: nextToken,
  });
};
