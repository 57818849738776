import {
  FC,
  ReactNode,
  useState,
  useRef,
  cloneElement,
  isValidElement,
} from "react";
import Markdown from "react-markdown";
import { TwButtons } from "../atoms/TwButtons";
import { ReactComponent as InfoSvg } from "../../assets/imgs/info.svg";
import { ReactComponent as SortArrowDown } from "../../assets/imgs/SortArrowDown.svg";
import { useOutsideAlerter } from "../atoms/detectClickOutside";
import { ReactComponent as Close } from "../../assets/imgs/close.svg";
import { ReactComponent as Code } from "../../assets/imgs/code.svg";
import {
  TStyle,
  useDivPosition,
  TPosition,
  useChildStyles,
} from "./GetPositionWrapper";
import "./TailwindModal.css";
import "./OpenPopup.css";

type TModalBtn = {
  text: string;
  type: "primary" | "secondary";
};

export const ModalBox: FC<{
  title?: string;
  content?: string;
  component?: ReactNode;
  btns?: TModalBtn[];
  setIsModalOpen?: (isModalOpen: boolean) => void;
}> = ({ title, content = "", component, btns, setIsModalOpen }) => {
  return (
    <div className="modal-box">
      {title && (
        <div className="modal-header">
          <h3>{title}</h3>
          <button
            type="button"
            className="button-modal"
            title="close modal"
            onClick={() => {
              if (setIsModalOpen) {
                setIsModalOpen(false);
              }
            }}
          >
            <Close />
          </button>
        </div>
      )}
      {component && component}
      {content !== "" && (
        <div className="modal-content">
          <Markdown>{content}</Markdown>
        </div>
      )}
      {btns && btns.length > 0 && (
        <div className="modal-footer">
          {btns.map((btn, idx) => (
            <div key={idx}>
              <TwButtons text={btn.text} type={btn.type} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const TailwindModal: FC<{
  title: string;
  content: string;
  setIsModalOpen: (isModalOpen: boolean) => void;
}> = ({ title, content, setIsModalOpen }) => {
  const modalBoxRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter({ ref: modalBoxRef, setOpen: setIsModalOpen });

  return (
    <div className="modal-container">
      <div className="modal-container-inner">
        <div ref={modalBoxRef}>
          <ModalBox
            title={title}
            content={content}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </div>
    </div>
  );
};

const parseMarkdown = (
  markdown: string
): { header: string; content: string } => {
  const regex = /^### (.+?)\n([\s\S]*)$/m;
  const match = markdown.match(regex);

  if (match) {
    const header = match[1].trim();
    const content = match[2].trim();
    return { header, content };
  }

  return { header: "", content: "" };
};

export type TDirection =
  | "left"
  | "right"
  | "bottom-left"
  | "center"
  | "top-right";

type TIcon = "info" | "warning" | "open" | "code";

type ModalComponentProps = {
  setIsModalOpen: (isOpen: boolean) => void;
};






export const OpenCloseModal: FC<OpenCloseModalParams> = ({
  btns,
  children,
  component,
  content = "",
  direction = "right",
  disabled,
  icon,
  isModalOpenDefault,
  mStyle = {
    minWidth: "400px",
  },
  size,
  title,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isModalOpenDefault);
  const modalBoxRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter({ ref: modalBoxRef, setOpen: setIsModalOpen });

  const { header, content: markdownContent } = parseMarkdown(content);
  const title2 = title || header;
  const content2 = markdownContent || content;

  const clonedComponent =
    component && isValidElement(component)
      ? cloneElement(
          component as React.ReactElement<Partial<ModalComponentProps>>,
          { setIsModalOpen }
        )
      : undefined;

  return (
    <div
      className={`oc-btn-and-modal-box${icon === "warning" ? " warning" : icon === "info" ? " info" : ""}`}
    >
      <span
        className="oc-btn"
        onClick={() => {
          if (disabled) return;
          setIsModalOpen(!isModalOpen);
        }}
      >
        {children && children}
        {!children && (icon === "warning" || icon === "info") && (
          <button
            type="button"
            className={`button ${icon === "warning" ? "orange" : "blue"}-outline round`}
          >
            <span className="sr-only">Warning</span>
            <InfoSvg />
          </button>
        )}
        {!children && icon === "open" && (
          <div className="button-and-popup-container">
            <button type="button" className="button">
              <span>Filter</span>
              <SortArrowDown />
            </button>
          </div>
        )}
        {!children && icon === "code" && (
          <div className="button-and-popup-container">
            <button type="button" className={`button icon gray-outline`}>
              <span className="sr-only">Display code</span>
              <Code />
            </button>
          </div>
        )}
      </span>
      {isModalOpen && (
        <div
          ref={modalBoxRef}
          className={`switch-and-position-box${size === "small" ? " small" : ""}${direction ? ` ${direction}` : ""}${icon === "warning" ? " warning" : icon === "info" ? " info" : ""}`}
          style={mStyle}
        >
          <ModalBox
            title={title2}
            content={content2}
            component={clonedComponent}
            btns={btns}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      )}
    </div>
  );
};

/*
if a parentposition parameter is passed to the wrapper, the left and right position of the modal is calculated dependent on the size of the parent compopnent
*/
type TSize = "small" | "medium" | "large";

/* 
the component passed as a parameter which opens when the button is clicked, must have an optional parameter setIsModalOpen  
*/

interface OpenCloseModalParams {
  btns?: TModalBtn[];
  children?: ReactNode;
  component?: ReactNode;
  content?: string;
  direction?: TDirection;
  disabled?: boolean;
  icon?: TIcon;
  isModalOpenDefault?: boolean;
  mStyle?: TStyle;
  parentPosition?: TPosition;
  size?: TSize;
  title?: string;
}

export const OpenCloseWithPositioning: FC<OpenCloseModalParams> = ({
  parentPosition,
  ...params
}) => {
  const { divRef, divPos } = useDivPosition();

  const mStyle = useChildStyles({
    parentPosition,
    divPos,
  });

  return (
    <div className="position-relative" ref={divRef}>
      <OpenCloseModal {...params} mStyle={mStyle} />
    </div>
  );
};
