import {
  IOptions,
  itemIF,
  EMetaFieldTypes,
  metaFieldIF,
} from "../../data/types/item";
import { getMeta } from "../meta/getMetas";
import { ANIMATABLE_PROPERTIES } from "../../data/css/constants/all";
import { itemTypes } from "../meta/getMetas";
import { snippetIF } from "../../data/types/snippets";

export const changeColor = ({
  colorItem,
  color,
}: {
  colorItem: itemIF;
  color: string;
}) => {
  if (!colorItem.value) {
    return colorItem;
  }
  const fieldName = colorItem.value.main ? "main" : "color";
  return {
    ...colorItem,
    value: {
      ...colorItem.value,
      [fieldName]: {
        ...colorItem.value[fieldName],
        value: color,
      },
    },
  };
};

const getFilter = ({
  svgId,
  svgs,
}: {
  svgId: number;
  svgs: itemIF[];
}): itemIF | undefined => {
  const svg = svgs.find((s) => s.id === svgId);
  if (svg) {
    const parent = svgs.find((s) => s.id === svg.parent);
    if (parent) {
      if (parent.name === "filter") {
        return parent;
      }
      return getFilter({ svgId: parent.id, svgs });
    }
  }
};

type TNewOpt = {
  fieldMeta: metaFieldIF;
  snippet?: snippetIF;
  editItemId?: number;
};

export const getOptions = ({
  fieldMeta,
  snippet,
  editItemId,
}: TNewOpt): IOptions[] => {
  const type = fieldMeta.type;
  const fieldMetaOptions = fieldMeta?.options;
  const addResultsToOptions = fieldMeta.addResultsToOptions;
  const getOptionsFromStylepackFonts = fieldMeta.getOptionsFromStylepackFonts;

  const options: IOptions[] = [];

  if (getOptionsFromStylepackFonts) {
    const stylepack = snippet?.data.stylepacks?.find(s => s.id === snippet.data.editStylepackId);
    const fonts = stylepack?.value?.stylepackFonts.value as itemIF[];
    if (!fonts) {
      return options;
    }

    fonts.forEach((f: itemIF) => {
      const newOption = {
        id: f.value?.font?.value,
      };
      options.push(newOption);
    });
    return options;
  }

  if (type === EMetaFieldTypes.propertyOfClassAndAll) {
    const transitionPropertyOptions = ["all"];

    const selectors = snippet?.data.selectors;
    const editSelector = selectors?.find(
      (s) => s.id === snippet?.data.editSelectorId
    );
    const propsOfClass = snippet?.data.properties?.filter(
      (p) => p.classId === editSelector?.id
    ) as itemIF[];

    propsOfClass?.forEach((p: itemIF) => {
      const meta = getMeta(itemTypes.CLASSES_CSS, p.name);
      const propName = meta?.addToShorthand ? meta?.addToShorthand : p.name;
      if (
        p.name &&
        !transitionPropertyOptions.includes(propName) &&
        ANIMATABLE_PROPERTIES.includes(propName)
      ) {
        transitionPropertyOptions.push(propName);
      }
    });
    return transitionPropertyOptions.map((o) => ({ id: o }));
  }

  // get from to for svg filter
  if (editItemId && addResultsToOptions && fieldMetaOptions) {
    const newOpts: IOptions[] = [];
    const svgs = snippet?.data?.svgs;
    if (!svgs) return newOpts;
    // svg filter effects: choose the result of another effect as in or in2 for the current effect
    const filter = getFilter({
      svgId: editItemId,
      svgs,
    });
    if (filter) {
      const children = svgs?.filter(
        (s) => s.parent === filter.id && s.id !== editItemId
      );
      if (children?.length && children.length > 0) {
        children.forEach((c) => {
          if (c.value?.result?.value) {
            newOpts.push({ id: c.value.result.value });
          }
        });
        return [...fieldMetaOptions, ...newOpts];
      }
    }
  }

  // get options from fieldMeta
  if (fieldMetaOptions) {
    return fieldMetaOptions;
  }

  return options;
};
