import React, { createContext, useReducer, useContext } from "react";
import { initialMain, initialMainIF } from "./constants";
import { PAGES } from "../settings/constants";
import { snippetIF, snippetsIF } from "../types/snippets";
import { getNewSnippet } from "../../data/snippet/initalValues";

export const getInitialState = (): initialMainIF => {
  // window.localStorage.removeItem("snippetState");
  // window.localStorage.removeItem("settingsState");

  const snippetStateLS = window.localStorage.getItem("snippetState");
  const settingsLS = window.localStorage.getItem("settingsState");

  const snippetStateLSParsed =
    snippetStateLS && snippetStateLS !== "undefined"
      ? JSON.parse(snippetStateLS) as snippetsIF
      : undefined;

  const settingsLSParsed =
    settingsLS && settingsLS !== "undefined"
      ? JSON.parse(settingsLS)
      : initialMain.settings;

  let selectedPage = PAGES.HOME;
  Object.keys(PAGES).forEach((p) => {
    if (window.location.href.includes(p.toLowerCase())) {
      if (
        p === PAGES.GALLERY ||
        p === PAGES.CODE ||
        p === PAGES.TERMS ||
        p === PAGES.PRIVACY ||
        p === PAGES.USER
      ) {
        selectedPage = p;
      }
    }
  });

  let initialSettings;
  if (settingsLSParsed) {
    initialSettings = settingsLSParsed;
  } else {
    initialSettings = initialMain.settings;
  }

  // there must be at least one content and one code snippet
  const newSnippetState: snippetsIF = snippetStateLSParsed?.snippets
    ? snippetStateLSParsed
    : {
        snippets: [] as snippetIF[],
        editSnippetId: "",
      };

  const snippets = newSnippetState.snippets || [];
  const hasSnippet = snippets[0];
  if (!hasSnippet) {
    const newSnippet = getNewSnippet({});
    snippets.push(newSnippet);
  }

  return {
    snippet: newSnippetState,
    settings: {
      ...initialSettings,
      sWidth: window.innerWidth,
      sHeight: "400",
      splitScreenMode: false,
      selectedPage,
    },
  };
};

interface IContextProps {
  state: initialMainIF;
  dispatch: React.Dispatch<any>;
}

export const StateContext = createContext({} as IContextProps);

export const StateProvider: React.FC<{
  reducer: React.Reducer<any, any>;
  initialState: initialMainIF;
  children: React.ReactNode;
}> = ({ reducer, initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
