import { snippetIF, snippetsIF } from "../types/snippets";
import { defaultStylepack2 } from "../stylepack/default";

import { v4 as uuidv4 } from "uuid";

export const starterSnippt = {
  id: "123",
  name: "New Snippet",
  description: "enter description here",
  content: false,
  public: false,
  data: {
    htmls: [
      { id: 4, position: 1, name: "div", level: 1, parent: 0 },
      {
        id: 5,
        position: 1,
        name: "text",
        value: { text: { value: "TEXT" } },
        level: 2,
        parent: 4,
      },
    ],
    editHtmlId: 5,
    selectors: [],
    editSelectorId: 0,
    properties: [],
    editPropertyId: 0,
    svgs: [],
    editSvgId: 0,
    stylepacks: [defaultStylepack2],
  },
  owner: "anonymous",
} as snippetIF;

export const getNewSnippet = ({
  isContentSnippet,
}: {
  isContentSnippet?: boolean;
}) => {
  const newSnippet = {
    ...starterSnippt,
    id: uuidv4(),
    name: "New snippet",
    content: isContentSnippet ? true : false,
  };
  return newSnippet;
};

export const initialSnippets: snippetsIF = {
  editSnippetId: "",
  snippets: [],
};
