import { FC } from "react";
import { OpenCloseWithPositioning } from "./TailwindModal";
import {
  TPosition,
  useDivPosition,
} from "./GetPositionWrapper";
import { TDirection } from "./TailwindModal";
import "./SectionTitle.css";

type TSectionTitle = {
  label: string;
  help?: string;
  warning?: string;
  direction?: TDirection;
  size?: "small" | "medium" | "large";
  parentPosition?: TPosition;

};

export const SectionTitle: FC<TSectionTitle> = ({
  label,
  help,
  warning,
  direction = "left",
  size = "medium",
  parentPosition,
}) => {

  const { divRef, divPos } = useDivPosition();
  const pPos = parentPosition ? parentPosition : divPos;

  return (
    <div className={`section-title ${size} ${direction} form-elements-wrapper`} ref={divRef}>
      <div>{label}</div>
      {(help || warning) && <div className="info-wrapper" >
        {help && (
          <OpenCloseWithPositioning
            icon="info"
            title={label}
            content={help}
            size="small"
            direction={direction}
            parentPosition={pPos}
          ></OpenCloseWithPositioning>
        )}
        {warning && (
          <OpenCloseWithPositioning
            icon="warning"
            title={label}
            content={warning}
            size="small"
            direction={direction}
            parentPosition={pPos}
          ></OpenCloseWithPositioning>
        )}
      </div>}
    </div>
  );
};
