import { useEffect, useState } from "react";
import { snippetIF } from "../../data/types/snippets";
import { fetchUsernames } from "../lambda/fetchUsernames";

type TUserIdsUsernames = {
  [key: string]: string;
};

export const useFetchUsernameForUserId = ({
  snippets,
}: {
  snippets: snippetIF[];
}): TUserIdsUsernames => {
  const [userIdsUsernames, setUserIdsUsernames] = useState<TUserIdsUsernames>(
    {}
  );
  useEffect(() => {
    const asyncFn = async () => {
      const userIds = snippets.map((s) => s.owner);
      const uniqueUserIds = Array.from(new Set(userIds)) as string[];
      if(uniqueUserIds.length === 0) {
        setUserIdsUsernames({});
        console.log("No unique user ids found on snippets");
        return;        
      };

      const response = await fetchUsernames(uniqueUserIds);
      if (response && typeof response === "string") {
        const parsedResponse = JSON.parse(response);
        if (parsedResponse.usernames) {
          setUserIdsUsernames(parsedResponse.usernames);
        }
      }
    };

    asyncFn();
  }, [snippets]);

  return userIdsUsernames;
};
