import { snippetIF } from "../../data/types/snippets";
import { getUserId } from "../signinup/handleTokens";
import { createSnippetGQL } from "../lambda/createServerSnippet";
import { MutationCreateSnippetArgs } from "cssexy-be/src/generated/graphql"

export const createServerSnippet = async ({
  editSnippet,
}: {
  editSnippet?: snippetIF;
}) => {
  if (!editSnippet) {
    console.error("No editSnippet found");
    return;
  }

  const cognitoUserId = getUserId();
  if (!cognitoUserId) {
    console.warn("No user id found");
    return editSnippet;
  }

  const snippetInput = {
    copyCount: editSnippet.copyCount || 1,
    data: editSnippet.data,
    description: editSnippet.description,
    name: editSnippet.name,
    owner: cognitoUserId,
    public: editSnippet.public ? true : false,
  } as MutationCreateSnippetArgs;

  const response = await createSnippetGQL({
    snippetData: snippetInput,
  });

  const newSnippet = response?.data?.createSnippet;
  if (newSnippet.id && newSnippet.createdAt) {
    return {
      ...editSnippet,
      id: newSnippet.id,
      createdAt: newSnippet.createdAt,
    } as snippetIF;
  }
};


