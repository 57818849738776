import { FC, useState, useEffect } from "react";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";
import { serverSnippetIF } from "../../data/types/snippets";
import { useStateContext } from "../../data/main/state";
import { setIsLoading } from "../../lib/reducer/settings/actions";
import { replaceAHref } from "../../lib/render/replaceAHref";
import { useFetchSnippets } from "../../lib/snippets/useFetchSnippets";
import { getUserId } from "../../lib/signinup/handleTokens";
import { Info } from "../atoms/alert/alert";
import { SnippetDetails } from "./SnippetDetails";
import { CustomIframe } from "../atoms/CustomIFrame";
import { SimplePagination } from "../atoms/Paging";
import { SortAndFilterGallery } from "./SortAndFilter";
import { useFetchUsernameForUserId } from "../../lib/snippets/useFetchUsernameForUserId";

import "./Gallery.css";

const GalleryItem: FC<{
  snippet: serverSnippetIF;
  setSelectedSnippet: (s: serverSnippetIF) => void;
  username?: string;
}> = ({ snippet, setSelectedSnippet, username }) => {
  const htmlString = replaceAHref(
    snippet.htmlString || "<div>SNIPPET IS EMPTY</div>"
  );

  return (
    <div className="gallery-item">
      <div
        className="overlay"
        onClick={() => {
          setSelectedSnippet(snippet);
        }}
      >
        <div>{snippet.name}</div>
        <div>{username}</div>
      </div>

      <CustomIframe
        title={`snippet preview for ${snippet.name}`}
        headContent={snippet.googleFontImportString}
        htmlSelectorCss={snippet.htmlSelectorCss}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: htmlString,
          }}
        />
      </CustomIframe>
    </div>
  );
};

const GalleryItems: FC<{
  setSelectedSnippet: (s: serverSnippetIF | undefined) => void;
}> = ({ setSelectedSnippet }) => {
  const { dispatch } = useStateContext();

  const [sortAndFilterParams, setSortAndFilterParams] =
    useState<QueryGetSnippetsArgs>({
      limit: 500,
      sort: {
        field: "updatedAt",
        direction: "DESC",
      },
      filter: {
        public: true,
      },
    });

  const { serverSnippets: snippets, loading } = useFetchSnippets({
    sortAndFilterParams,
  });

  const userId = getUserId();
  const userIdUserNames = useFetchUsernameForUserId({ snippets });

  useEffect(() => {
    if (loading) {
      dispatch(setIsLoading(true));
    } else {
      dispatch(setIsLoading(false));
    }
  }, [loading, dispatch]);

  const [displaySnippetIds, setDisplaySnippetsIds] = useState([] as string[]);
  const snippetIds = snippets.map((s) => s.id);
  const displaySnippets = snippets.filter((s) =>
    displaySnippetIds.includes(s.id)
  );

  return (
    <div className="container gallery-container mt-2r">
      <div className="title mb-1r">Snippet Gallery</div>

      <SortAndFilterGallery
        sortAndFilterParams={sortAndFilterParams}
        setSortAndFilterParams={setSortAndFilterParams}
      />
      {snippets.length === 0 && (
        <div>
          <Info isAlert={true}>NO SNIPPET FOUND.</Info>
        </div>
      )}

      {snippets.length > 0 && (
        <>
          <div className="mb-1r">
            <SimplePagination
              snippetIds={snippetIds}
              setDisplaySnippets={setDisplaySnippetsIds}
            />
          </div>
          <div className="gallery-box row">
            {displaySnippets.map((snippet, i) => {
              const username =
                snippet.owner === userId
                  ? "You"
                  : snippet.owner && userIdUserNames[snippet.owner]
                    ? userIdUserNames[snippet.owner]
                    : "No owner";
              return (
                <div className="col-12 col-lg-6" key={snippet.id}>
                  <GalleryItem
                    snippet={snippet}
                    setSelectedSnippet={setSelectedSnippet}
                    username={username}
                  />
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const Gallery: FC = () => {
  const [selectedSnippet, setSelectedSnippet] = useState(
    undefined as serverSnippetIF | undefined
  );

  if (selectedSnippet) {
    return (
      <SnippetDetails
        snippet={selectedSnippet}
        setSelectedSnippet={() => {
          setSelectedSnippet(undefined);
        }}
      />
    );
  }

  return <GalleryItems setSelectedSnippet={setSelectedSnippet} />;
};

export default Gallery;
