import axios from "axios";
import { MutationCreateSnippetArgs } from "cssexy-be/src/generated/graphql";

const graphqlEndpoint = process.env.REACT_APP_SNIPPETS_API_URL || "";
const apiKey = process.env.REACT_APP_SNIPPETS_API_KEY || "";

export const createSnippetGQL = async ({
  snippetData,
}: {
  snippetData: MutationCreateSnippetArgs;
}) => {
  try {
    const graphqlQuery = {
      query: `
      mutation CreateSnippet($content: Boolean, $copyCount: Int, $data: AWSJSON!, $description: String, $name: String!, $owner: String!, $public: Boolean) {
        createSnippet(content: $content, copyCount: $copyCount, data: $data, description: $description, name: $name, owner: $owner, public: $public) {
          id
          createdAt
        }
      }
    `,
      variables: { ...snippetData, data: JSON.stringify(snippetData.data) },
    };

    const response = await axios.post(
      graphqlEndpoint,
      JSON.stringify(graphqlQuery),
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": apiKey,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error creating snippet: ", error);
  }
};
