import { FC, useEffect, useState } from "react";
import { ReactComponent as ArrowLeft } from "../../assets/imgs/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/imgs/arrow-right.svg";
import "./Paging.css";
import { snippetIF } from "../../data/types/snippets";

type TSize = "normal" | "large";
export const Paging: FC<{ size?: TSize }> = ({ size }) => {
  return (
    <nav
      className={`flowbite-pagination-with-icons${size === "large" ? " large" : ""}`}
    >
      <ul>
        <li>
          <a href="#home">
            <span className="sr-only">Previous</span>
            <ArrowLeft />
          </a>
        </li>
        <li>
          <a href="#home" className="active">
            <span>1</span>
          </a>
        </li>
        <li>
          <a href="#home">
            <span>2</span>
          </a>
        </li>
        <li>
          <a href="#home">
            <span>3</span>
          </a>
        </li>
        <li>
          <a href="#home">
            <span className="sr-only">Next</span>
            <ArrowRight />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export const SimplePagination: FC<{
  snippetIds: string[];
  setDisplaySnippets: (ids: string[]) => void;
  itemsPerPage?: number;
}> = ({ snippetIds, setDisplaySnippets, itemsPerPage = 10 }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(snippetIds.length / itemsPerPage);

  const start = (currentPage - 1) * itemsPerPage;
  const last = currentPage * itemsPerPage;
  const end = last > snippetIds.length ? snippetIds.length : last;


  useEffect(() => {
    setDisplaySnippets(snippetIds.slice(start, end));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
const totalPages = Math.ceil(snippetIds.length / itemsPerPage);
  if (currentPage > totalPages) {
    setCurrentPage(1);
  }
  }, [snippetIds, currentPage, itemsPerPage]);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const previousIsDisabled = currentPage === 1;
  const nextIsDisabled = currentPage === totalPages;

  return (
    <div className="form-elements-wrapper table-data-pagination-wrapper">
      <button
        type="button"
        className={`button icon blue-outline${previousIsDisabled ? " disabled" : ""}`}
        onClick={goToPreviousPage}
      >
        <span className="sr-only">Previous</span>
        <ArrowLeft />
      </button>
      <span>
        Showing <strong>{start + 1}</strong> to{" "}
        <strong>{end}</strong> of <strong>{snippetIds.length}</strong>
      </span>
      <button
        type="button"
        className={`button icon blue-outline${nextIsDisabled ? " disabled" : ""}`}
        onClick={goToNextPage}
      >
        <span className="sr-only">Next</span>
        <ArrowRight />
      </button>
    </div>
  );
};
