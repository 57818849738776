import { itemIF, metaIF, TObject, TShorthand, TPosAndSh } from "../../data/types/item";
import { getMetasOfItems } from "../meta/getMetas";
import { itemTypes } from "../meta/getMetas";
import { getItemValuesAsObj } from "./getItemValuesAsObject";
import { getItemName } from "./getItemName";

export const buildSubGroups = ({
  items,
  metas,
}: {
  items: itemIF[];
  metas: { [key: string]: metaIF };
}) => {
  const groups = [] as itemIF[][];
  let group = [] as itemIF[];

  items.forEach((item) => {
    const meta = metas[item.name];
    if (meta?.shorthandPropertyOrder) {
      // start a new group
      if (group.length > 0) {
        groups.push(group);
      }
      group = [item];
      return;
    }
    group.push(item);
  });

  if (group.length > 0) {
    groups.push(group);
  }

  return groups;
};

const addSingleItemToShorthand = ({
  item,
  meta,
  itemType,
  items,
  stylepack,
}: {
  item: itemIF;
  meta: metaIF;
  itemType: itemTypes;
  items: itemIF[];
  stylepack?: itemIF;
}) => {
  const sh = {} as TShorthand;

  const propName = getItemName({
    item,
    meta,
  });
  
  if (!propName) {
    return;
  }
  if (!sh[propName]) {
    sh[propName] = [] as string[];
  }

  let itemValueStr = "";
  const attr: TObject = {};
  getItemValuesAsObj({
    itemType,
    item,
    attr,
    items,
    stylepack,
  });

  Object.keys(attr).forEach((key) => {
    const space = itemValueStr === "" ? "" : " ";
    itemValueStr += `${space}${attr[key]}`;
  });

  if (sh[propName].length === 0) {
    sh[propName].push(itemValueStr);
  } else {
    sh[propName].push(
      `${meta.addMultipleTimes ? "ADDMULTIPLETIMES" : " "}${itemValueStr}`
    );
  }

  return sh;
};

const getShortHandStr = ({
  items,
  metas,
}: {
  items: itemIF[];
  metas: { [key: string]: metaIF };
}) => {
  let shorthandStr = "";
  items.find((item) => {
    const meta = metas[item.name];
    if (meta?.addToShorthand) {
      shorthandStr = meta.addToShorthand;
      return true;
    }
    return false;
  });
  if (shorthandStr !== "") {
    return shorthandStr;
  }
};

const getGroupShorthandSeparator = ({
  items,
  metas,
}: {
  items: itemIF[];
  metas: { [key: string]: metaIF };
}) => {
  let groupShorthandSeparator = "";
  items.forEach((item) => {
    const meta = metas[item.name];
    if (meta?.shorthandPropertyOrder && meta.shorthandSeparator) {
      groupShorthandSeparator = meta.shorthandSeparator;
      return;
    }
  });
  if (groupShorthandSeparator !== "") {
    return groupShorthandSeparator;
  }
};

const buildValueStrForShorthandGroup = ({
  items,
  metas,
  itemType,
}: {
  items: itemIF[];
  metas: { [key: string]: metaIF };
  itemType: itemTypes;
}) => {
  const shorthandStr = getShortHandStr({
    items,
    metas,
  });
  if (!shorthandStr) {
    return;
  }

  const groupShorthandSeparator = getGroupShorthandSeparator({
    items,
    metas,
  });

  let groupValueStr = "";
  const sorted = items.slice().sort((a, b) => a.position - b.position);
  const firstAppearancePosition = sorted[0].position;
  for (const item of sorted) {
    const meta = metas[item.name];
    if (!meta.addToShorthand) {
      return;
    }

    const attr: TObject = {};
    getItemValuesAsObj({
      itemType,
      item,
      attr,
      items,
    });
    let itemValueStr = "";
    Object.keys(attr).forEach((key) => {
      const spaceItemValueStr = itemValueStr === "" ? "" : " ";
      itemValueStr += `${spaceItemValueStr}${attr[key]}`;
    });

    const space =
      groupValueStr === ""
        ? ""
        : groupShorthandSeparator
          ? groupShorthandSeparator
          : meta.shorthandSeparator
            ? meta.shorthandSeparator
            : " ";
    groupValueStr += space + itemValueStr;
  }

  const sh = {} as TShorthand;

  if (!sh[shorthandStr]) {
    sh[shorthandStr] = [] as string[];
  }
  sh[shorthandStr].push(
    `${sh[shorthandStr].length === 0 ? "" : ", "}${groupValueStr}`
  );

  return {
    pos: firstAppearancePosition,
    sh,
  };
};

export const sortGroup = ({
  group,
  metas,
}: {
  group: itemIF[];
  metas: { [key: string]: metaIF };
}) => {
  const mainItem = group.find((item) => {
    const meta = metas[item.name];
    if (meta?.shorthandPropertyOrder) {
      return true;
    }
    return false;
  });

  if (!mainItem) {
    return group;
  }

  const shorthandPropertyOrder = metas[mainItem.name]?.shorthandPropertyOrder;
  if (!shorthandPropertyOrder) {
    return group;
  }

  const sorted = [] as itemIF[];
  shorthandPropertyOrder.forEach((itemName) => {
    const itemInGroupWithNameInOrder =
      itemName === "THIS"
        ? mainItem
        : group.find((item) => item.name === itemName);

    if (itemInGroupWithNameInOrder) {
      sorted.push(itemInGroupWithNameInOrder);
    }
  });
  return sorted;
};

export const getShortHand = ({
  items,
  itemType,
  stylepack,
}: {
  items: itemIF[];
  itemType: itemTypes;
  stylepack?: itemIF;
}) => {
  const metas = getMetasOfItems({ items, itemType });
  const singleItems = items.filter((item) => {
    const meta = metas[item.name];
    if (!meta) {
      return false;
    }
    const shorthandStr = meta.addToShorthand;
    if (!shorthandStr) {
      return true;
    }
    return false;
  });

  const sorted = singleItems.slice().sort((a, b) =>
    a.name === b.name
      ? a.position > b.position
        ? 1
        : a.position < b.position
          ? -1
          : 0
      : 0
  );

  const posAndShS = [] as TPosAndSh[];

  sorted.forEach((item) => {
    const meta = metas[item.name];
    if (!meta) {
      return;
    }
    const sh = addSingleItemToShorthand({
      item,
      meta,
      itemType,
      items,
      stylepack,
    });
    if (sh) {
      posAndShS.push({ pos: item.position, sh });
    }
  });

  const itemsWithShorthandStr = {} as { [key: string]: itemIF[] };

  for (const item of items) {
    const meta = metas[item.name];
    if (!meta) {
      continue;
    }
    const shorthandStr = meta.addToShorthand;
    if (!shorthandStr) {
      continue;
    }

    if (!itemsWithShorthandStr[shorthandStr]) {
      itemsWithShorthandStr[shorthandStr] = [item];
    } else {
      itemsWithShorthandStr[shorthandStr].push(item);
    }
  }

  Object.keys(itemsWithShorthandStr).forEach((shorthandStr) => {
    const group = itemsWithShorthandStr[shorthandStr];

    const hasOrder = group.find((i) => {
      const meta = metas[i.name];
      if (!meta) {
        return false;
      }
      return meta.shorthandPropertyOrder;
    });

    if (!hasOrder) {
      const posAndSh = buildValueStrForShorthandGroup({
        items: group,
        metas,
        itemType,
      });
      if (posAndSh) {
        posAndShS.push(posAndSh);
      }
      return;
    }

    const groups = buildSubGroups({
      items: group,
      metas,
    });

    groups.forEach((group) => {
      const sorted = sortGroup({ group, metas });
      const posAndSh = buildValueStrForShorthandGroup({
        items: sorted,
        metas,
        itemType,
      });
      if (posAndSh) {
        posAndShS.push(posAndSh);
      }
    });
  });

  const sorted2 = posAndShS.slice().sort((a, b) => a.pos - b.pos); 
  return sorted2;
};


export const addPseudoelementContent = ({
  selector,
  selectorProperties,
  shortHand,
}: {
  selector: itemIF;
  selectorProperties?: itemIF[];
  shortHand: { [key: string]: string[] };
}) => {
  if (["::before", "::after"].includes(selector.name)) {
    if (!shortHand.content) {
      const content = selector.value?.main?.value;
      // don't render pseudo elements which have no CSS and a content attribute with an empty string
      if (
        content === "" &&
        (!selectorProperties || selectorProperties.length === 0)
      ) {
        return;
      }
      shortHand.content = [`"${content}"`];
    } else {
      const content = shortHand.content[0];
      if (content.includes("data:image")) {
        shortHand.content = [unescape(content)];
      }
    }
  }
};
