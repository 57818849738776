import { useStateContext } from "../../data/main/state";
import { itemTypes } from "../../lib/meta/getMetas";
import { metaIF } from "../../data/types/item";
import { addItem } from "../../lib/reducer/items/actions";
import { getAddItemIsDisabled } from "../../lib/items/getIsItemDisabled";
import { getItemsInCategory } from "../../utils/css/main";
import { getNewItemBody } from "../../lib/tree/manipulateItemNew";
import { SectionTitle } from "../modules/SectionTitle";

const AddHtmlBtns = ({ items }: { items: metaIF[] }) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
    dispatch,
  } = useStateContext();

  const itemType = itemTypes.BLOCK_HTML;
  const editSnippet = snippets.find((s) => s.id === editSnippetId);
  const htmls = editSnippet?.data?.htmls;
  const editHtmlId = editSnippet?.data?.editHtmlId;
  const editHtml = htmls?.find((h) => h.id === editHtmlId);
  const parent = htmls?.find((i) => i.id === editHtml?.parent);

  if (!htmls) return null;

  const itemsInCategories = getItemsInCategory({
    itemType: itemTypes.BLOCK_HTML,
    withCategories: true,
  });

  const disabledMetas = getAddItemIsDisabled({
    editItem: editHtml,
    parent,
    items: itemsInCategories,
  });

  return (
    <div className="btn-row form-elements-wrapper">
      {items.map((item) => {
        const isDisabled = disabledMetas.includes(item.name);
        return (
          <button
            key={item.name}
            className={`button small blue-outline${isDisabled ? " disabled" : ""}`}
            onClick={() => {
              if (isDisabled) return;

              const newItem1 = getNewItemBody({
                itemType,
                itemName: item.name,
              });
              dispatch(
                addItem({
                  item: newItem1,
                  itemType,
                  addAfterItemId: editHtmlId,
                })
              );
            }}
          >
            {item.display ? item.display : item.name}
          </button>
        );
      })}
    </div>
  );
};

export const AddHtmlBoxWithButton = () => {
  const itemsInCategories = getItemsInCategory({
    itemType: itemTypes.BLOCK_HTML,
    withCategories: true,
  });

  return (
    <>
      <div className="mb-1r">
        <SectionTitle label="Add HTML" size="large" />
      </div>
      {itemsInCategories.map((iic) => {
        return (
          <div key={iic.name} className="add-buttons-box mb-1r">
            <SectionTitle label={iic.title!} help={iic.help} direction="left" />
            <AddHtmlBtns items={iic.items} />
          </div>
        );
      })}
    </>
  );
};
