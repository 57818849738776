import { FC } from "react";
import { useStateContext } from "../../data/main/state";
import { itemIF } from "../../data/types/item";
import { assignCssVariable } from "../../lib/reducer/items/actions";
import { OpenCloseModal } from "../modules/TailwindModal";
import {
  SortAndFindChildren,
  TSortBy,
} from "../modules/SortAndFind/SortAndFindItems";

const cssVariableHelp = `
If there are CSS variables in the current snippet, click on the button to assign one of them to the current CSS property.

The value of the CSS property then is overwritten by \`var(--variableName)\`.

You must make sure that 
- the CSS variable belongs to a selector which is assigned to the target HTML or one of its ancestors
- the value of the variable fits the CSS property to which you assign it. 

For example:

\`\`\`
.variables {
--color-primary: blue;
}
.button.primary {
  background-color: var(--color-primary);
}
<div class="variables">
  <button class="button primary">Primary button</button>
</div>
\`\`\`  
`;

export const options = ["name A-Z", "name Z-A"] as TSortBy[];

const VariableButtons: FC<{
  items: itemIF[];
  assignedCssVariableName?: string;
  setIsModalOpen?: (isOpen: boolean) => void;
  editSnippetIdOther?: string;
}> = ({
  items,
  assignedCssVariableName,
  setIsModalOpen,
  editSnippetIdOther,
}) => {
  const { dispatch } = useStateContext();
  return (
    <div className="btn-row mt-1r">
      {items.length === 0 && (
        <button
          type="button"
          className="button blue-outline disabled"
          onClick={() => {}}
        >
          No CSS variable
        </button>
      )}

      {items.map((i) => {
        return (
          <button
            key={i.id}
            type="button"
            className={`button ${assignedCssVariableName === i.value?.name?.value ? "blue" : "blue-outline"}`}
            onClick={() => {
              dispatch(
                assignCssVariable({
                  variableId: i.id || 0,
                  cssVariableName: i.value?.name?.value,
                  editSnippetId: editSnippetIdOther,
                })
              );
              setIsModalOpen && setIsModalOpen(false);
            }}
          >
            {`--${i.value?.name?.value}`}
          </button>
        );
      })}
    </div>
  );
};

const AssignVariableModalInner: FC<{
  sortedVariables: itemIF[];
  assignedCssVariableName?: string;
  editSnippetIdOther?: string;
  setIsModalOpen?: (isOpen: boolean) => void;
}> = ({ sortedVariables, assignedCssVariableName, editSnippetIdOther }) => {
  return (
    <div className="p-1r pl-15r">
      <SortAndFindChildren allItems={sortedVariables}>
        <VariableButtons
          items={sortedVariables}
          assignedCssVariableName={assignedCssVariableName}
          editSnippetIdOther={editSnippetIdOther}
        />
      </SortAndFindChildren>
    </div>
  );
};

// show btn for all variables of the same time as the current CSS property
export const AssignVariable = ({
  assignedCssVariableName,
  fieldIsDisabled,
  editSnippetIdOther,
}: {
  assignedCssVariableName?: string;
  fieldIsDisabled?: boolean;
  editSnippetIdOther?: string;
}) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
    },
  } = useStateContext();

  const esId = editSnippetIdOther || editSnippetId;

  const editSnippet = snippets.find((s) => s.id === esId);
  const variables = editSnippet?.data?.properties?.filter(
    (p) => p.name === "variable"
  );
  const sortedVariables = variables
    ? [...variables].sort((a, b) => a.position - b.position)
    : [];

  const isDisabled = fieldIsDisabled || sortedVariables.length === 0;

  if (isDisabled) {
    return (
      <button
        type="button"
        className="button blue-outline disabled"
        onClick={() => {}}
      >
        No CSS variable
      </button>
    );
  }

  return (
    <div className="flex">
      <OpenCloseModal
        title="Select CSS variable"
        component={
          <AssignVariableModalInner
            sortedVariables={sortedVariables}
            assignedCssVariableName={assignedCssVariableName}
            editSnippetIdOther={editSnippetIdOther}
          />
        }
        direction="left"
      >
        <button
          type="button"
          className={`button ${assignedCssVariableName ? "blue" : "blue-outline"}${isDisabled ? " disabled" : ""}`}
        >
          {assignedCssVariableName
            ? `--${assignedCssVariableName}`
            : "Select variable"}
        </button>
      </OpenCloseModal>
      <div className="ml-1r">
        <OpenCloseModal
          title="Assign CSS variable"
          content={cssVariableHelp}
          icon="info"
          direction="left"
        />
      </div>
    </div>
  );
};
