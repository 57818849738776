import { useEffect, useState, FC } from "react";
import { ReactComponent as Download } from "../../assets/imgs/cloud-download.svg";
import { ReactComponent as CloudOff } from "../../assets/imgs/cloud-off.svg";
import { serverSnippetIF, snippetIF } from "../../data/types/snippets";
import { getUserGroups } from "../../lib/signinup/handleTokens";
import { deleteSnippetGQL } from "../../lib/lambda/deleteSnippet";
import { PreviewWithToolbar2 } from "../preview/PreviewWithToolbar2";
import { Tooltip } from "../atoms/tooltip";
import { OpenCloseWithPositioning } from "../modules/TailwindModal";
import { SectionTitle } from "../modules/SectionTitle";
import { useDivPosition } from "../modules/GetPositionWrapper";
import { GalleryBreadcrumb } from "./Breadcrumbs";
import { EditSnippet } from "../forms/EditSnippet";

import "./SnippetDetails.css";
import "./Breadcrumb.css";
import { setIsLoading } from "../../lib/reducer/settings/actions";

const ConfirmCloudSnippetDelete: FC<{
  snippetId?: string;
  setIsModalOpen?: (isOpen: boolean) => void;
}> = ({ snippetId, setIsModalOpen }) => {
  return (
    <div className="p-1r pl-15r">
      <p>
        Do you really want to delete the cloud snippet? If it is gone it is
        gone.
      </p>
      <div className="flex gap-1 mt-2r mb-1r">
        <button
          type="button"
          className="button orange"
          onClick={() => {
            const asyncFn = async () => {
              setIsLoading(true);
              const resonse = await deleteSnippetGQL({ id: snippetId || "" });
              console.log(
                "resonses ----------------------------------- ",
                resonse
              );

              setIsLoading(false);
              setIsModalOpen && setIsModalOpen(false);
            };
            asyncFn();
          }}
        >
          Delete
        </button>
        <button
          type="button"
          className="button gray-outline"
          onClick={() => {
            setIsModalOpen && setIsModalOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const CloudSnippetActionBtns: FC<{
  snippet?: snippetIF;
  isMySnippet?: boolean;
  isAdmin?: boolean;
  isSignedIn?: boolean;
}> = ({ snippet, isMySnippet, isAdmin, isSignedIn }) => {
  const { divRef, divPos } = useDivPosition();

  if (!snippet) return null;

  const canDownload = isSignedIn && (!isMySnippet || isAdmin);
  const canDelete = isSignedIn && (isAdmin || isMySnippet);

  return (
    <div className="form-elements-wrapper">
      <div className="btn-row" ref={divRef}>
        {((isSignedIn && !isMySnippet) || isAdmin) && (
          <button
            type="button"
            className={`button blue icon tooltip-container-parent${canDownload ? "" : " disabled"}`}
            onClick={() => {
              if (!canDownload) return;

              console.log("download snippet");
            }}
          >
            <span className="sr-only">Download snippet</span>
            <Download />
            <Tooltip
              tooltipText="Add to your local collection"
              direction="top-left"
            />
          </button>
        )}

        {(isAdmin || isMySnippet) && (
          <OpenCloseWithPositioning
            title="Delete selector"
            component={<ConfirmCloudSnippetDelete snippetId={snippet?.id} />}
            disabled={!canDelete}
            parentPosition={divPos}
          >
            <button
              type="button"
              className={`button orange icon ${canDelete ? "" : " disabled"}`}
              onClick={() => {}}
            >
              <span className="sr-only">Delete snippet</span>
              <CloudOff />
            </button>
          </OpenCloseWithPositioning>
        )}
      </div>
    </div>
  );
};

const gallerySnippetHelp = `### Edit, delete or download snippet

If you are signed in and the snippet is yours, you can edit or delete the snippet.

If you are signed in but the snippet is not yours, you can copy and download the snippet to be used in your own projects.
`;

export const SnippetDetails = ({
  snippet,
  setSelectedSnippet,
}: {
  snippet: serverSnippetIF;
  setSelectedSnippet: (selectedSnippet: serverSnippetIF | undefined) => void;
}) => {
  const [groups, setGroups] = useState([] as string[]);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const asyncFn = async () => {
      const response = getUserGroups();
      if (!response) return;

      const { groups, userId } = response;
      if (groups) setGroups(groups);
      if (userId) setUserId(userId);
    };
    asyncFn();
  }, []);

  const isSignedIn = groups.length > 0;
  const isMySnippet = snippet.owner === userId;
  const isAdmin = groups.includes("admin");

  const actionTitle =
    isAdmin && !isMySnippet
      ? "Edit, download or delete cloud snippet (admin)"
      : isMySnippet
        ? "View or delete my cloud snippet"
        : isSignedIn
          ? "View or download cloud snippet"
          : "View cloud snippet";

  return (
    <div className="container snippet-details-box mt-1r">
      <h2 className="pb-1r">Snippets</h2>

      <GalleryBreadcrumb setSelectedSnippet={setSelectedSnippet} />

      <PreviewWithToolbar2
        snippetName={snippet.name}
        snippetHtml={snippet.htmlString || ""}
        snippetFonts={snippet.googleFontImportString || ""}
        htmlCss={snippet.htmlSelectorCss || ""}
      />

      <div className="mb-1r">
        <SectionTitle
          label={actionTitle}
          size="large"
          help={gallerySnippetHelp}
        />
      </div>

      <div className="flex-6633">
        <div className="flex-66">
          <SectionTitle label={isAdmin ? "Edit snippet" : "Display snippet"} />

          <EditSnippet snippet={snippet} isDisabled={!isAdmin} />
        </div>
        <div className="flex-33">
          <div className="mb-1r">
            <SectionTitle
              label={
                isAdmin
                  ? "Download or delete"
                  : isMySnippet
                    ? "Delete"
                    : isSignedIn
                      ? "Download"
                      : ""
              }
            />
          </div>

          <CloudSnippetActionBtns
            snippet={snippet}
            isSignedIn={isSignedIn}
            isMySnippet={isMySnippet}
            isAdmin={isAdmin}
          />
        </div>
      </div>
    </div>
  );
};
