import { FC, useState } from "react";
import { useStateContext } from "../../data/main/state";
import { ReactComponent as UploadToCloud } from "../../assets/imgs/cloud-upload.svg";
import { setIsLoading } from "../../lib/reducer/settings/actions";
import { replaceSnippet } from "../../lib/reducer/snippet/actions";
import { getUserId } from "../../lib/signinup/handleTokens";
import { syncSnippet } from "../../lib/lambda/syncSnippet";
import { Tooltip } from "../atoms/tooltip";
import { Notification } from "../atoms/Notification";

export const SyncEditSnippetBtn: FC<{ notificationPosition?: "right" }> = ({
  notificationPosition,
}) => {
  const {
    state: {
      snippet: { snippets, editSnippetId },
      settings: { isLoading },
    },
    dispatch,
  } = useStateContext();

  const [msg, setMsg] = useState("");
  const [showMsg, setShowMsg] = useState(false);

  const editSnippet = snippets?.find((s) => s.id === editSnippetId);
  const userId = getUserId() as string | undefined;

  const isBtnDisabled = !userId || isLoading;
  const btnClassName = `toolbar-button${isBtnDisabled ? " disabled" : ""} tooltip-container-parent`;
  const tooltipText = isBtnDisabled
    ? "Sign in to cloud sync the current snippet"
    : "Cloud sync the current snippet";

  return (
    <div
      className={btnClassName}
      onClick={() => {
        if (!userId || isLoading || !editSnippet) {
          setMsg("Error: You need to be signed in to upload snippets.");
          setShowMsg(true);
          return;
        }

        const asyncFn = async () => {
          dispatch(setIsLoading(true));

          const newOrUpdatedSnippet = await syncSnippet({
            snippet: editSnippet,
            userId,
          });
          if (newOrUpdatedSnippet) {
            dispatch(
              replaceSnippet({
                oldSnippetId: editSnippetId,
                newSnippet: newOrUpdatedSnippet,
              })
            );

            const msg = newOrUpdatedSnippet.createdAt
              ? "Snippet is created in the cloud"
              : "Snippet is updated in the cloud";
            setMsg(msg);
            setShowMsg(true);
          } else {
            setMsg("Error: Failed to create or update snippet in the cloud.");
            setShowMsg(true);
          }
          dispatch(setIsLoading(false));
        };
        asyncFn();
      }}
    >
      <UploadToCloud />
      <Tooltip tooltipText={tooltipText} direction="left-center" />

      {showMsg && (
        <Notification
          msg={msg}
          notificationPosition={notificationPosition}
          setShowMsg={setShowMsg}
        />
      )}
    </div>
  );
};
