import React, {
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
  Children,
} from "react";

export type TPosition = {
  x: number;
  y: number;
  width: number;
  height: number;
};
export type TStyle = {
  left?: number;
  right?: number;
  minWidth?: string;
};

export const useDivPosition = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const [divPos, setDivPos] = useState<TPosition | undefined>(undefined);

  useEffect(() => {
    if (divRef.current) {
      const divRect = divRef.current.getBoundingClientRect();
      setDivPos({
        x: divRect.x,
        y: divRect.y,
        width: divRect.width,
        height: divRect.height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef.current]);

  return { divRef, divPos };
};

export const useChildStyles = ({
  parentPosition,
  divPos,
}: {
  parentPosition?: TPosition;
  divPos?: TPosition;
}) => {
  const [mStyle, setMStyle] = useState<TStyle | undefined>(undefined);
  useEffect(() => {
    if (parentPosition && divPos) {
      const mStyle = getStyle({ pPos: parentPosition, mPos: divPos });
      setMStyle(mStyle);
    }
  }, [parentPosition, divPos]);

  return mStyle;
};

/*
sets the left and right of the modal dependent on the width of the container
*/

export const getStyle = ({
  pPos,
  mPos,
}: {
  pPos?: TPosition;
  mPos?: TPosition;
}): TStyle | undefined => {
  if (!pPos || !mPos || mPos.width === 0)  {
    return ;
  }

  const BX = mPos.x; //button x
  const BW = mPos.width; // button width
  const CX = pPos.x; // container x
  const CW = pPos.width; // container width

  if (CW <= 400) {
    // the container width is smaller than 400

    return {
      right: (BX + BW) - (CX + CW),
      left: CX - BX,
    };
  }

  if (((BX - CX) + BW) <= 400) {
    // the distance between the right side of the modal button and the left side of the container is less then 400

    return {
      right: ((BX+BW) - 400),
      left: CX - BX,
    };
  }

  // the distance between the right side of the modal button and the left side of the container is greater than 400

  return {
    right: 0,
    left: BW-400,
  };
};

/*
wraps a child component in a div with a useRef hook
after component load it gets the outer positions of the child with ref.current.getBoundingClientRect()
it passes the outer positions to the child as parentPosition
if within the child (e.g. SectionTitle) another component (e.g. OpenCloseModal) is wrapped itself in GetPositionWrapper, 
then SectionTitle passes its outer position as parentPosition to GetPositionWrapper around OpenCloseModal
and then within OpenCloseModal, if parent width is < 400, mStyle is available 
which sets the left or right of the modal not at the left or right of the open modal button, 
but at the left or right of the parent component (e.g. SectionTitle)
*/

export const GetPositionWrapper: FC<{
  parentPosition?: TPosition;
  children: ReactNode;
}> = ({ parentPosition, children }) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [divPos, setDivPos] = useState(undefined as TPosition | undefined);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      const divRect = div.getBoundingClientRect();
      setDivPos({
        x: divRect.x,
        y: divRect.y,
        width: divRect.width,
        height: divRect.height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [divRef]);

  const childrenWithProps = Children.map(children, (child) => {
    if (parentPosition && divPos) {
      const mStyle = getStyle({ pPos: parentPosition, mPos: divPos });
      if (React.isValidElement<{ mStyle?: TStyle }>(child)) {
        return React.cloneElement(child, { mStyle });
      }
    }
    if (divPos) {
      if (
        React.isValidElement<{
          parentPosition?: TPosition;
        }>(child)
      ) {
        return React.cloneElement(child, {
          parentPosition: divPos,
        });
      }
    }
    return child;
  });

  return <div ref={divRef}>{childrenWithProps}</div>;
};
