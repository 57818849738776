import { useState, useEffect } from "react";
import { serverSnippetIF } from "../../data/types/snippets";
import { snippetIF } from "../../data/types/snippets";
import { getFilteredAndSortedSnippets } from "../lambda/getSnippets";
import { renderCssAndHtmlForSnippet } from "./renderCssAndHtmlForSnippet";
import { QueryGetSnippetsArgs } from "cssexy-be/src/generated/graphql";

const saveSnippetsToLocal = (snippets: snippetIF[]) => {
  localStorage.setItem("serverSnippets", JSON.stringify(snippets));
};
// const getSnippetsFromLocal = (): snippetIF[] => {
//   const snippetsJson = localStorage.getItem("serverSnippets");
//   return snippetsJson ? JSON.parse(snippetsJson) : [];
// };

export const useFetchSnippets = ({
  sortAndFilterParams,
}: {
  sortAndFilterParams: QueryGetSnippetsArgs;
}) => {
  const [serverSnippets, setServerSnippets] = useState<serverSnippetIF[]>([]);
  const [loading, setLoading] = useState(true);
  const [cssRenderComplete, setCssRenderComplete] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const filteredAndSorted = await getFilteredAndSortedSnippets({
        sortAndFilterParams,
      });

      // just as a backup if offline
      saveSnippetsToLocal(filteredAndSorted);

      const newSnippets = await Promise.all(
        filteredAndSorted.map(async (s) => {
          const snippetWithCssAndHtmlRendered =
            await renderCssAndHtmlForSnippet(s);
          return snippetWithCssAndHtmlRendered;
        })
      );
      setServerSnippets(newSnippets.filter(Boolean) as serverSnippetIF[]);
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortAndFilterParams.sort, sortAndFilterParams.filter]);

  useEffect(() => {
    if (
      serverSnippets.length > 0 &&
      !serverSnippets.some((s) => !s.snippetCss)
    ) {
      setCssRenderComplete(true);
    }
  }, [serverSnippets]);

  return { serverSnippets, loading, cssRenderComplete };
};
